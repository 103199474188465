<template>
  <section class="pt-4 px-0 px-xl-4 h-100">
    <b-container fluid>
      <b-row class="pb-3">
        <b-col cols="3">
          <b-select v-model="brandId" :options="brands" />
        </b-col>
        <b-col cols="2">
          <b-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Cerca"
          />
        </b-col>
        <b-col cols="6" class="text-right">
          <h1>
            {{ brandName }}
            <b-badge variant="warning" class="text-dark">{{
              currencyFormatter(total)
            }}</b-badge>
          </h1>
        </b-col>
        <b-col cols="1" class="text-right">
          <b-checkbox v-model="downloadPrice">Prezzi</b-checkbox>
          <b-button variant="primary" @click="downloadExcel"
            ><font-awesome-icon class="mr-2" icon="file-excel"/>
            <font-awesome-icon icon="download"
          /></b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-table
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          v-model="visibleRows"
          :filter="filter"
          class="mt-3"
          outlined
          sticky-header="75vh"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle m-2" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template slot="top-row">
            <td colspan="10" class="text-right">Total</td>
            <td>
              <strong>{{ currencyFormatter(total) }}</strong>
            </td>
            <td></td>
            <td>
              <strong>{{ currencyFormatter(totalStock) }}</strong>
            </td>
            <td />
          </template>
          <template #cell(salesPerPeriod)="data">
            {{ data.item.salesPerPeriod.toFixed(3) }}
          </template>
          <template #cell(safetyStock)="data">
            {{
              data.item.safetyStock
                ? data.item.safetyStock.toFixed(3)
                : data.item.safetyStock
            }}
          </template>
          <template #cell(costPrice)="data">
            {{ currencyFormatter(data.item.costPrice) }}
          </template>
          <template #cell(arrivalDate)="data">
            {{ showDate(data.item.arrivalDate) }}
          </template>
          <template #cell(lastOrderDate)="data">
            {{ showDate(data.item.lastOrderDate) }}
          </template>
          <template #cell(surplusValueStock)="data">
            {{ currencyFormatter(data.item.surplusValueStock) }}
          </template>
          <template #cell(surplusStockDays)="data">
            {{
              data.item.surplusStockDays === 999999999999
                ? "INF"
                : data.item.surplusStockDays
            }}
          </template>
          <template #cell(suggestQuantity)="data">
            <b-form-input
              type="number"
              min="0"
              v-model="data.item.suggestQuantity"
            />
          </template>
        </b-table>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import * as XLSX from "xlsx";

export default {
  name: "SupplyManager",
  data() {
    return {
      fields: [
        { key: "code", label: "Codice", sortable: true },
        { key: "name", label: "Descrizione" },
        { key: "costPrice", label: "Prezzo Netto" },
        {
          key: "arrivalDate",
          label: "Data Arrivo",
          thStyle: { width: "120px" }
        },
        {
          key: "lastOrderDate",
          label: "Data Ultimo Acq.",
          thStyle: { width: "120px" }
        },
        { key: "salesPerPeriod", label: "Consumo Giornaliero" },
        { key: "goodOnHand", label: "Q.tà Inv." },
        { key: "purchaseQuantity", label: "Q.tà Imp." },
        { key: "safetyStock", label: "Stock Sicurezza" },
        { key: "restockingLevel", label: "Livello Riordino" },
        {
          key: "suggestQuantity",
          label: "Quantità Suggerita",
          variant: "warning",
          sortable: true
        },
        { key: "surplusStock", label: "Eccedenza Stock", sortable: true },
        {
          key: "surplusValueStock",
          label: "Eccedenza Valore Stock",
          sortable: true
        },
        {
          key: "surplusStockDays",
          label: "Eccedenza Stock (gg)",
          sortable: true
        }
      ],
      items: [],
      brandId: null,
      brands: [],
      isBusy: false,
      visibleRows: [],
      filter: null,
      downloadPrice: false
    };
  },
  mounted() {
    this.getBrand();
  },
  watch: {
    brandId() {
      this.isBusy = true;
      this.items = [];
      this.getTurnover();
    },
    items(value) {
      if (value.length > 0) this.isBusy = false;
    }
  },
  computed: {
    total() {
      return this.visibleRows.reduce((accum, item) => {
        return accum + item.costPrice * item.suggestQuantity;
      }, 0);
    },
    totalStock() {
      return this.visibleRows.reduce((accum, item) => {
        return accum + item.surplusValueStock;
      }, 0);
    },
    brandName() {
      if (this.brandId)
        return this.brands.find(option => option.value === this.brandId)
          .brandName;
      return "";
    }
  },
  methods: {
    getBrand: function() {
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}brand-all`)
        .then(res => {
          this.brands = this.brandSelectAdapter(res.data);
        })
        .catch(err => {
          this.error = err;
        });
    },
    brandSelectAdapter: function(data) {
      let result = [{ value: null, text: "Selezionare un brand" }];
      data.map(function(element) {
        result.push({
          value: element.id,
          text: element.name + " (" + element.itemsNumber + ")",
          brandName: element.name
        });
      });
      this.isBusy = false;
      return result;
    },
    getTurnover: function() {
      this.$http
        .get(
          `${process.env.VUE_APP_API_ENDPOINT}turnover/restocking?brandId=${this.brandId}`
        )
        .then(res => {
          this.items = res.data;
        })
        .catch(err => {
          this.error = err;
        });
    },
    currencyFormatter(number) {
      let currencyFormatter = new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR"
      });
      return currencyFormatter.format(number);
    },
    showDate(date) {
      if (date) return date.split(" ")[0];
      return "";
    },
    downloadExcel() {
      const downloadPrice = this.downloadPrice;
      const order = this.visibleRows.reduce((ac, order) => {
        if (order.suggestQuantity > 0) {
          let item = {
            code: order.code,
            description: order.name
          };
          if (downloadPrice) item.price = order.costPrice;
          item.quantity = order.suggestQuantity;
          ac.push(item);
        }
        return ac;
      }, []);
      const orderWS = XLSX.utils.json_to_sheet(order);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, orderWS);
      XLSX.writeFile(
        wb,
        this.brandName + "_order_" + this.getCurrentDate() + ".xlsx"
      );
    },
    getCurrentDate() {
      const date = new Date();
      return (
        date.getUTCFullYear() +
        "-" +
        ("0" + (date.getUTCMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getUTCDate()).slice(-2)
      );
    }
  }
};
</script>
